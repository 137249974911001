import React from "react";

function Customer() {
  return (
    <section className="testimonials">
      <div className="container">
        <div className="row d-flex">
          <div className="col-12">
            <h2 className="headingStyle">
              What <span className="fontBlack"> Customer</span>Says About Us
            </h2>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ZBsLTmjj36Q?si=BxVUyuYrRcfxeY_f"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AYRztnsRPRs?si=lJ3FsDGpt6eKL_be"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/1vfExY6rr_4?si=j1OojQcoRpmXKpG8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/FAyElWjO_oA?si=KVEtKQ4U6PAALy8t"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/yz1VjQQOTvg?si=liHHvxSLwuJOakIy"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mb-2">
            <div className="img-box">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/mQAgwdhq9AM?si=jq-HcIHpeN2FUH3r"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-12 text-center">
            <a href="#" target="_blank" className="btn-more">
              Show More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customer;
