import React from "react";
import Slider from "react-slick";

import featured1 from "../../assets/images/featured/outlook1.jpg";
import featured2 from "../../assets/images/featured/outlook2.jpg";
import featured3 from "../../assets/images/featured/outlook3.jpg";
import featured4 from "../../assets/images/featured/outlook4.jpg";
import featured5 from "../../assets/images/featured/outlook5.jpg";
import featured6 from "../../assets/images/featured/outlook6.jpg";
import featured7 from "../../assets/images/featured/outlook7.jpg";
import featured8 from "../../assets/images/featured/outlook8.jpg";
function Featured() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section className="featured-in advantages">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              We have been <span>Featured On</span>
            </h2>

            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <div className="f_slid">
                    <img src={featured1} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured2} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured3} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured4} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured5} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured6} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured7} className="f_img_slid" />
                  </div>
                </div>
                <div>
                  <div className="f_slid">
                    <img src={featured8} className="f_img_slid" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
