import React from "react";
import Slider from "react-slick";
import gallery0 from "../../assets/images/gallery/bp0.jpg";

import g1 from "../../assets/images/gallery/bp0.jpg";
import g2 from "../../assets/images/gallery/bp1.jpg";
import g3 from "../../assets/images/gallery/bp3.jpg";
import g4 from "../../assets/images/gallery/bp4.jpg";
import g5 from "../../assets/images/gallery/bp5.jpg";
import g6 from "../../assets/images/gallery/bp6.jpg";
import g7 from "../../assets/images/gallery/bp7.jpg";
import g8 from "../../assets/images/gallery/bp8.jpg";
import g9 from "../../assets/images/gallery/bp9.jpg";
import g10 from "../../assets/images/gallery/bp10.png";

function Gallery() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section className="collections">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="bgcolor1">
              <h2>Gallery of KW Blue Pearl</h2>
            </div>

            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g1} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g2} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g3} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g4} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g5} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g6} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g7} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g8} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g9} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className=" ">
                    <div className="item">
                      <div className="collection-box">
                        <img src={g10} className="g_img" />
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
