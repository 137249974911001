import React from "react";
import Slider from "react-slick";
import img1 from "../../assets/images/slider-image/award1.jpg";
import img3 from "../../assets/images/slider-image/award3.jpg";
import img4 from "../../assets/images/slider-image/award4.jpg";
import img5 from "../../assets/images/slider-image/award5-1.jpg";
// import img51 from "../../assets/images/slider-image/award5-1.jpg";
// import img6 from "../../assets/images/slider-image/award6.jpg";
// import img7 from "../../assets/images/slider-image/award7.jpg";
// import img8 from "../../assets/images/slider-image/award8.jpg";

function AwardsRecognition() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="featured-in bg-color1">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>
                Awards &amp; <span>Recognition</span>
              </h2>

              {/* ---reposive--silder --- */}

              <div className="slider-container">
                <Slider {...settings}>
                  <div>
                    <div className="award_slid">
                      <img src={img1} className="featured_sli_img" />
                    </div>
                  </div>

                  <div>
                    <div className="award_slid">
                      <img src={img3} className="featured_sli_img" />
                    </div>
                  </div>

                  <div>
                    <div className="award_slid">
                      <img src={img4} className="featured_sli_img" />
                    </div>
                  </div>

                  <div>
                    <div className="award_slid">
                      <img src={img4} className="featured_sli_img" />
                    </div>
                  </div>

                  <div>
                    <div className="award_slid">
                      <img src={img5} className="featured_sli_img" />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AwardsRecognition;
