import brand1 from "../../assets/images/brands/Haldiram.jpg";
import brand2 from "../../assets/images/brands/Barista.jpg";
import brand3 from "../../assets/images/brands/Californian-Burrito.jpg";
import brand4 from "../../assets/images/brands/Dominos.jpg";
import brand5 from "../../assets/images/brands/Wow-momo.jpg";
import brand6 from "../../assets/images/brands/mcdonald's.jpg";
import brand7 from "../../assets/images/brands/KFC.jpg";
import brand8 from "../../assets/images/brands/Subway.jpg";
import brand9 from "../../assets/images/brands/Dunkin-Donuts.jpg";
import brand10 from "../../assets/images/brands/keventer.jpg";
import brand11 from "../../assets/images/brands/Natural-Icecream.jpg";
import brand12 from "../../assets/images/brands/Manyavar.jpg";
import brand13 from "../../assets/images/brands/Baggit.jpg";
import brand14 from "../../assets/images/brands/Forest-Essentials.jpg";
import brand15 from "../../assets/images/brands/Body-shop.jpg";
import brand16 from "../../assets/images/brands/Naykaa.jpg";
import brand17 from "../../assets/images/brands/Bombay-selection.jpg";
import brand18 from "../../assets/images/brands/meenabazaar.jpg";
import brand19 from "../../assets/images/brands/ritukumar.jpg";
import brand20 from "../../assets/images/brands/ethnics-by-raymond.jpg";
import brand21 from "../../assets/images/brands/ctc.jpg";
import brand22 from "../../assets/images/brands/looks.jpg";
import brand23 from "../../assets/images/brands/oyster.jpg";
import brand24 from "../../assets/images/brands/libaas.jpg";
import brand25 from "../../assets/images/brands/beinghuman.jpg";
import { v4 as uuidv4 } from "uuid";

const data = [
  { id: uuidv4(), img: brand1 },
  { id: uuidv4(), img: brand2 },
  { id: uuidv4(), img: brand3 },
  { id: uuidv4(), img: brand4 },
  { id: uuidv4(), img: brand5 },
  { id: uuidv4(), img: brand6 },
  { id: uuidv4(), img: brand7 },
  { id: uuidv4(), img: brand8 },
  { id: uuidv4(), img: brand9 },
  { id: uuidv4(), img: brand10 },
  { id: uuidv4(), img: brand11 },
  { id: uuidv4(), img: brand12 },
  { id: uuidv4(), img: brand13 },
  { id: uuidv4(), img: brand14 },
  { id: uuidv4(), img: brand15 },
  { id: uuidv4(), img: brand16 },
  { id: uuidv4(), img: brand17 },
  { id: uuidv4(), img: brand18 },
  { id: uuidv4(), img: brand19 },
  { id: uuidv4(), img: brand20 },
  { id: uuidv4(), img: brand21 },
  { id: uuidv4(), img: brand22 },
  { id: uuidv4(), img: brand23 },
  { id: uuidv4(), img: brand24 },
  { id: uuidv4(), img: brand25 },
];

function Brand() {
  return (
    <section className="brands bg-color1">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              Associated Brands with <span>KW Blue Pearl</span>
            </h2>
            <div className="brand-row">
              {data.map((item) => {
                return <img src={item.img} alt="" />;
              })}
              {/* <img src="images/Haldiram.jpg" alt />
              <img src="images/Barista.jpg" alt />
              <img src="images/Californian-Burrito.jpg" alt />
              <img src="images/Dominos.jpg" alt />
              <img src="images/Wow-momo.jpg" alt />
              <img src="images/mcdonald's.jpg" alt />
              <img src="images/KFC.jpg" alt />
              <img src="images/Subway.jpg" alt />
              <img src="images/Dunkin-Donuts.jpg" alt />
              <img src="images/keventer.jpg" alt />
              <img src="images/Natural-Icecream.jpg" alt />
              <img src="images/Manyavar.jpg" alt />
              <img src="images/Baggit.jpg" alt />
              <img src="images/Forest-Essentials.jpg" alt />
              <img src="images/Body-shop.jpg" alt />
              <img src="images/Naykaa.jpg" alt />
              <img src="images/Bombay-selection.jpg" alt />
              <img src="images/meenabazaar.jpg" alt />
              <img src="images/ritukumar.jpg" alt />
              <img src="images/ethnics-by-raymond.jpg" alt />
              <img src="images/ctc.jpg" alt />
              <img src="images/looks.jpg" alt />
              <img src="images/oyster.jpg" alt />
              <img src="images/libaas.jpg" alt />
              <img src="images/beinghuman.jpg" alt /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brand;
