import FloorPlans from "../../components/floorPlans/FloorPlans"



function FloorPlansPage() {
  return (
    <FloorPlans/>
  )
}

export default FloorPlansPage
