// import videograph from "../../assets/images/investment/blue-pearl-graph-video.jpg"
function Investment() {
  return (
    <>
      <section className="imp">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <h2>Must Know:-</h2>
            </div>
            <div className="col-md-6">
              <h4>
                Investment/ Shop/Property starts from - ₹ <strong>33</strong>
                Lac
              </h4>
              <h4>
                Get Rental ₹ <strong>28,00,000</strong>
              </h4>
              <h4>
                Rent for <strong>9</strong> Years
              </h4>
            </div>
            <div className="col-md-4">
              <h4>Ready to Fit Out</h4>
              <h4>CC Obtained</h4>
              <h4>For Safe Investment - Hand to Hand Registry</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="counterSection bg-color1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 cardbox">
              <div className="box">
                <span className="iconBox">
                  <i className="icon hp" />
                  <h3 className="counter">
                    <span className="count">3700</span>+
                  </h3>
                </span>
                <div className="counterTitle">
                  <h2>Happy Customers</h2>
                </div>
              </div>
              <div className="box">
                <span className="iconBox">
                  <i className="icon yr" />
                  <h3 className="counter">
                    <span className="count">25</span>+
                  </h3>
                </span>
                <div className="counterTitle">
                  <h2>Years Of Experience</h2>
                </div>
              </div>
              <div className="box md">
                <span className="iconBox">
                  <i className="icon ct" />
                  <h3 className="counter">
                    <span className="count">39</span>+ Lacs Sq Ft
                  </h3>
                </span>
                <div className="counterTitle">
                  <h2>Delivered On Time</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="growth-chart">
                <h3>
                  Investment growth from <strong>2014 to 2024</strong>
                </h3>

                <a className="video-player-button">
                  <iframe
                    width="100%"
                    height="355"
                    src="https://www.youtube.com/embed/XQs6YJ4ftew?si=UwvEHksAWRmmeI5E"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Investment;
