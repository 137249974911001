import "./assets/css/main.css";
import "./assets/css/plugins.css";
import "./assets/css/custom.css";
import "./assets/css/slick.css";
import HeaderPage from "./pages/header";
import InvestmentPage from "./pages/investment";
import PariceistPage from "./pages/priceList";
import AgentDealerPage from "./pages/agentDealer";
import FloorPlansPage from "./pages/floorPlans";
import AwardsPage from "./pages/awards";
import FeaturedPage from "./pages/featured";
import GalleryPage from "./pages/gallery";
import ProjectPage from "./pages/project";
import BrandsPage from "./pages/brands";
import CustomerPage from "./pages/customer";
import FooterPage from "./pages/footer";

function App() {
  return (
    <>
      <HeaderPage />
      <InvestmentPage />
      <PariceistPage />
      <AgentDealerPage />

      <FloorPlansPage />

      <AwardsPage />
      <FeaturedPage />
      <GalleryPage />

      <ProjectPage />
      <BrandsPage />

      <CustomerPage />
      <FooterPage />
    </>
  );
}

export default App;
